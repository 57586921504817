import { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, addDoc, doc, getFirestore } from "firebase/firestore";

const useNews = () => {
  const db = getFirestore();
  const [newsList, setNewsList] = useState([]);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsContent, setNewsContent] = useState("");
  const [showModal, setShowModal] = useState(false);

  // Move fetchNews outside of useEffect
  const fetchNews = async () => {
    const newsCollection = collection(db, "news");
    const newsSnapshot = await getDocs(newsCollection);
    const newsData = newsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setNewsList(newsData);
  };

  useEffect(() => {
    fetchNews(); // Call fetchNews inside useEffect
  }, []); // Empty dependency array means this will run only once, like componentDidMount

  const addNews = async () => {
    try {
      await addDoc(collection(db, "news"), {
        newsTitle: newsTitle,
        newsContent: newsContent,
        createdAt: new Date(),
      });
      setNewsTitle("");
      setNewsContent("");
      setShowModal(false);
      fetchNews(); // Re-fetch news after adding
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const deleteNews = async (newsId) => {
    try {
      await deleteDoc(doc(db, "news", newsId));
      setNewsList(newsList.filter((news) => news.id !== newsId));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  return {
    newsList,
    newsTitle,
    setNewsTitle,
    newsContent,
    setNewsContent,
    showModal,
    setShowModal,
    addNews,
    deleteNews,
  };
};

export default useNews;
