import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth"; // Import your auth hook
import useNews from "../hooks/useNews"; // Import your news hook

const AdminPage = () => {
  const isAuthenticated = useAuth(); // Use the authentication hook

  const {
    newsList,
    newsTitle,
    setNewsTitle,
    newsContent,
    setNewsContent,
    showModal,
    setShowModal,
    addNews,
    deleteNews,
  } = useNews(); // Use the news hook

  if (!isAuthenticated) {
    return null; // User is not authenticated, so we don't render the page
  }

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Admin Page</h1>

      {/* Tab controls */}
      <div className="btn-group mb-4" role="group">
        <button type="button" className="btn btn-primary">
          News Control
        </button>
        <button type="button" className="btn btn-outline-primary">
          Settings
        </button>
      </div>

      <div className="card">
        <div className="card-body">
          <h2 className="card-title">News Control</h2>
          <p className="card-text">Manage your news content here.</p>
          <button className="btn btn-success" onClick={() => setShowModal(true)}>
            Add News
          </button>

          <div className="mt-4">
            {newsList.length > 0 ? (
              <ul className="list-group">
                {newsList.map((news) => (
                  <li key={news.id} className="list-group-item">
                    <h5>{news.newsTitle}</h5>
                    <p>{news.newsContent}</p>
                    <div className="btn-group">
                      <button className="btn btn-warning btn-sm">Edit</button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => deleteNews(news.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No news available.</p>
            )}
          </div>
        </div>
      </div>

      {/* Modal for adding news */}
      {showModal && (
        <div className="modal show" style={{ display: "block" }} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add News</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setShowModal(false);
                    setNewsTitle("");
                    setNewsContent("");
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addNews();
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="newsTitle">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="newsTitle"
                      value={newsTitle}
                      onChange={(e) => setNewsTitle(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="newsContent">Content</label>
                    <textarea
                      className="form-control"
                      id="newsContent"
                      rows="3"
                      value={newsContent}
                      onChange={(e) => setNewsContent(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit News
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
