/*import Home from "../components/pages/Home/Home";
import About from "../components/organisms/About/About";
import Contact from "../components/organisms/Contact/Contact";
import Services from "../components/organisms/Services/Services";
import MultiLayout from "../components/ui_templates/Layouts/MultiLayout";
*/
//
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
// home ()
import About from "../pages/AboutPage";
import Support from "../pages/support";
// under products
//under AG
import AgTractors from "../pages/agTractors"; // double nested
import AgImplements from "../pages/agImplements";
import AgHarvesters from "../pages/agHarvesters";
import Falcon from "../pages/falcon"; // double nested
import Rovic from "../pages/rovic"; // double nested
import Mospare from "../pages/mospare"; // double nested

import Construction from "../pages/construction"; //nested
import Forestry from "../pages/forestry"; //nested
import Turf from "../pages/turf"; //nested
import Home from "../pages/HomePage";
import Product from "../pages/Product";
import LawnGarden from "../pages/lawngarden";
import PrecisionAg from "../pages/PrecisionAg";
import AdminPage from "../components/AdminPage";
import LoginPage from "../components/Login";

const routes = [
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "products",
    element: <Product />,
    children: [
      {
        path: "lawn-garden",
        element: <LawnGarden />,
      },
      {
        path: "turf",
        element: <Turf />,
      },
      {
        path: "construction",
        element: <Construction />,
      },
      {
        path: "forestry",
        element: <Forestry />,
      },
      {
        path: "agTractors",
        element: <AgTractors />,
      },
      {
        path: "agHarvesters",
        element: <AgHarvesters />,
      },
      {
        path: "agImplements",
        element: <AgImplements />,
      },
      {
        path: "PrecisionAg",
        element: <PrecisionAg /> 
      },
      {
        path: "falcon",
        element: <Falcon />,
      },
      {
        path: "rovic",
        element: <Rovic />,
      },
      {
        path: "mospare",
        element: <Mospare />,
      },
    ],
  },
  {
    path: "service",
    element: <Product />,
    children: [
      {
        path: "sales-support",
        element: <Support />,
      },
    ],
  },
  {
    path: "/news",
    element: <Product />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/admin",
    element: <AdminPage />,
  },
];

export default routes;
